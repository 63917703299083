<template>
  <div>

    <div class="edit_box mrb20">
      <el-button icon="el-icon-plus" type="primary" @click="handleEdit()">新增商品</el-button>
      <el-button icon="el-icon-upload2" type="success" @click="batchPutaway(1)">上架</el-button>
      <el-button icon="el-icon-download" type="success" @click="batchPutaway(0)">下架</el-button>
      <el-button icon="el-icon-delete" type="danger" @click="batchdelete">删除</el-button>
    </div>

    <div class="mrb20 bg-white pd20">
      <div class="mrb20">数据列表</div>
      <div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="id" prop="id">
          </el-table-column>

          <el-table-column label="排序" prop="sort">
          </el-table-column>

          <el-table-column prop="status" label="上架状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" @change="changeStatus($event,scope.row.id);">
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="address" label="商品图片" width="120">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.picture]" style="width: 100px; height: 100px" :src="scope.row.picture" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="120">
          </el-table-column>

          <el-table-column prop="price" label="价格">
          </el-table-column>
          <el-table-column prop="stock" label="总量">
          </el-table-column>
          <el-table-column prop="sales" label="销售">
          </el-table-column>

          <el-table-column prop="category_name" label="归属分类">
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    searchForm: {},
  },
  data() {
    return {
      pageInfo: {
        limit: parseInt(sessionStorage.getItem("limit")) || 10,
        page: parseInt(sessionStorage.getItem("page")) || 1,
        count: 0,
        pageNum: 0,
      },
      srcList: [], //图片预览
      tableData: [],
      ids: [],
      evaluatePictureList: "",
    };
  },

  created() {
    this.getList(this.pageInfo);
    console.log("searchForm", this.searchForm);
  },
  mounted() {},
  computed: {},

  methods: {
    async changeStatus(event, goods_id) {
      console.log(event);
      console.log(goods_id);

      const { msg } = await this.$Api.changeGoodStatus({
        goods_id: goods_id,
        status: event,
      });

      return this.$message({
        message: msg,
        type: "success",
      });
    },
    // 点击查看大图
    handlePriveImg(arr) {
      this.srcList = arr.map((item) => item.url);
    },
    async getList() {
      if (this.searchForm.status == "") {
        this.searchForm.status = "ALL";
      }
      const { data } = await this.$Api.sportGoodList({
        limit: this.pageInfo.limit,
        page: this.pageInfo.page,
        ...this.searchForm,
      });
      console.log("data", data);
      this.tableData = data.list;
      this.pageInfo.limit = parseInt(data.limit);

      sessionStorage.setItem("limit", this.pageInfo.limit);

      this.pageInfo.page = parseInt(data.page);

      sessionStorage.setItem("page", this.pageInfo.page);

      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;

      console.log(data, "成功的列表");
    },

    handleSelectionChange(value) {
      // console.log("选择批量的东西", val);
      this.ids = value.map((item) => item.id);
    },
    handleEdit(id) {
      this.$router.push({
        path: "/sport/addedit",
        query: { id: id },
      });
    },
    handleSizeChange(val) {
      console.log("val", val);
      this.pageInfo.limit = val;

      sessionStorage.setItem("limit", parseInt(val));
      sessionStorage.setItem("page", 1);

      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      console.log("val", val);
      this.pageInfo.page = val;

      sessionStorage.setItem("page", parseInt(val));

      this.getList(this.pageInfo);
    },
    // 上架下架
    async batchPutaway(status) {
      let parameter = {
        ids: this.ids,
        status: status,
      };
      const data = await this.$Api.goodsBattleChange(parameter);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("修改成功");
      console.log("批量成功", data);
      this.getList(this.pageInfo);
    },
    // 批量删除
    batchdelete() {
      this.$confirm("确定要批量删除改商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.goodsBattleDel({ ids: this.ids });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
    // 单个删除
    handleDelete(row) {
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.goodsDetailDel({ ids: [row] });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>