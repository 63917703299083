<template>
  <div>

    <div class="inquire_box mrb20">
      <div class="mrb20">筛选查询</div>
      <div>
        <el-form ref="searchForm" :model="searchForm" label-width="100px" class="flex flex-wrap ">

          <el-form-item label="商品名称">
            <el-input v-model="searchForm.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="searchForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in sportsStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onSubmit">查找</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <goodslist :searchForm="searchForm" ref="goodslist"></goodslist>
    <!--  -->
  </div>
</template>

<script>
// import goodslist from "./goodslist.vue";
import goodslist from "./goodslist";
import { sportsStatus } from "@/utils/statusData";

export default {
  components: {
    goodslist,
  },
  props: {},
  data() {
    return {
      sportsStatus,
      searchForm: {
        name: "",
        status: "",
      },
      animebrand: [],
      brand: [],
      Author: [],
      original: [],
      figure: [],
      classify: [],
      active: "1",
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {
    onSubmit() {
      this.$refs.goodslist.getList();
    },
  },
};
</script>
<style lang='scss' scoped>
.inquire_box {
  ::v-deep .el-input__inner {
    width: 200px;
  }
}

.inquire_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
}
</style>